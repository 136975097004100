import { Outlet } from "react-router-dom";


import styles from "./Layout.module.css";



const Layout = () => {
    return (
        <div className="container flex ">
            <div className={styles.layout}>
                <Outlet />
            </div>
            {/* <SideBarDrawer /> */}
            {/* <FolderTree/> */}
            {/* <FABFeedBack /> */}
        </div>
    );
};

export default Layout;
